.MiningHandler {
  text-align: center;
  position: absolute;
  left: 47%;
  top: 35%;
}

.StatsPanel {
  position: absolute;
  left: 0rem;
}

.ButtonGroup {
  position: absolute;
  left: 32rem;
  border-radius: 10px 10px 10px;
}


.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #1a1a2e 0%, #16162c 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
}

.loader {
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}
