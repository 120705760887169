.forgot-password-container {
  text-align: center;
  color: white;
}

.instruction-text {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 2rem;
  font-size: 0.9rem;
}

.success-message {
  background: rgba(40, 167, 69, 0.1);
  border: 1px solid rgba(40, 167, 69, 0.2);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
}

.back-button {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.back-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.forgot-input-box {
  width: 180%;
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
  margin-bottom: 1rem;
  margin-top: 20px;
  position: relative;
  transform: translateX(65px);
}

.back-link {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.back-link:hover {
  color: white;
}
