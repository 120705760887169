.crafting-button {
  border: none;
  background: transparent;
  padding: 12px 24px;
  border-radius: 12px;
  color: white;
  font-weight: bold;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.crafting-button:hover {
  transform: scale(1.2);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(4px);
  z-index: 1000;
  animation: fadeIn 0.2s ease-out;
}

.crafting-modal {
  background: linear-gradient(180deg, #1a1a2e 0%, #16162c 100%);
  border-radius: 20px;
  padding: 2rem;
  width: 100%;
  max-width: 1200px;
  max-height: 85vh;
  overflow-y: auto;
  margin: 20px;
  position: relative;
  animation: scaleIn 0.3s ease-out;
  box-shadow: 0 0 50px rgba(74, 58, 255, 0.1);
}

.close-button {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: rotate(90deg);
}

.crafting-title {
  font-size: 2rem;
  font-weight: bold;
  background: linear-gradient(135deg, #fff 0%, #00CED1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.5rem;
  text-align: center;
}

.pickaxe-card {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  padding: 1.5rem;
  height: 100%;
  position: relative;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
}

.pickaxe-card:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.05);
}

.pickaxe-name {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-shadow: 0 0 10px currentColor;
}

.rarity-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: white;
}

.rarity-badge.common { background: rgba(122, 122, 122, 0.2); border: 1px solid #7a7a7a; }
.rarity-badge.uncommon { background: rgba(46, 204, 113, 0.2); border: 1px solid #2ecc71; }
.rarity-badge.rare { background: rgba(52, 152, 219, 0.2); border: 1px solid #3498db; }
.rarity-badge.epic { background: rgba(155, 89, 182, 0.2); border: 1px solid #9b59b6; }
.rarity-badge.legendary { background: rgba(241, 196, 15, 0.2); border: 1px solid #f1c40f; }
.rarity-badge.mythical { background: rgba(233, 30, 99, 0.2); border: 1px solid #e91e63; }

.perks-list {
  margin: 1rem 0;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.perk {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.9rem;
}

.perk:before {
  content: "•";
  color: currentColor;
  margin-right: 0.5rem;
}

.recipe {
  margin-top: auto;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.recipe h4 {
  color: white;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  opacity: 0.9;
}

.requirement {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  padding: 0.2rem 0;
}

.has-materials {
  color: #2ecc71;
}

.missing-materials {
  color: #e74c3c;
}

.craft-button {
  width: 100%;
  margin-top: 1rem;
  padding: 0.8rem;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.craft-button:not(:disabled) {
  background: linear-gradient(135deg, rgba(255,255,255,0.1), rgba(255,255,255,0.05));
  color: white;
}

.craft-button:disabled {
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.3);
  cursor: not-allowed;
}

.craft-button:not(:disabled):hover {
  transform: translateY(-1px);
  background: linear-gradient(135deg, rgba(255,255,255,0.15), rgba(255,255,255,0.1));
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.crafting-modal::-webkit-scrollbar {
  width: 6px;
}

.crafting-modal::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 3px;
}

.crafting-modal::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.crafting-modal::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.15);
}

.pickaxe-card.owned {
  border-width: 2px;
  border-color: rgba(46, 204, 113, 0.3);
  box-shadow: 0 0 15px rgba(46, 204, 113, 0.1);
}

.pickaxe-card.equipped {
  border-width: 2px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.craft-button.equipped {
  background: linear-gradient(135deg, rgba(46, 204, 113, 0.2), rgba(46, 204, 113, 0.1)) !important;
  border: 1px solid #2ecc71 !important;
  cursor: default;
  color: #2ecc71;
}

.craft-button.equipped:hover {
  transform: none;
  box-shadow: none;
}

.craft-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.requirement {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.has-materials {
  color: #2ecc71;
}

.missing-materials {
  color: #e74c3c;
}
