/* MiningLog.css */
.mining-log-container {
  max-height: 60vh;
  overflow-y: auto;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.log-entries {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.log-entry {
  background: rgba(255, 255, 255, 0.05);
  padding: 0.75rem 1rem;
  border-radius: 8px;
  transition: transform 0.2s ease;
}

.log-entry:hover {
  background: rgba(255, 255, 255, 0.08);
  transform: translateX(4px);
}

.log-icon {
  background: rgba(0, 0, 0, 0.3);
  padding: 8px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ore-name {
  font-weight: bold;
  text-shadow: 0 0 10px currentColor;
}

.animate-fade-in {
  animation: fadeInLeft 0.3s ease-out;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Customize scrollbar */
.mining-log-container::-webkit-scrollbar {
  width: 8px;
}

.mining-log-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.mining-log-container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.mining-log-container::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}
