.shop-button {
  color: white;
  padding: 12px 28px;
  font-weight: bold;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  background: transparent;
  border: none;
}

.shop-button:hover {
  transform: scale(1.3);
  cursor: pointer;
}

.shop-button:active {
  transform: translateY(1px);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(8px);
  z-index: 1000;
  animation: fadeIn 0.2s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shop-modal {
  background: linear-gradient(165deg, #1a1a2e 0%, #16162c 100%);
  border-radius: 24px;
  padding: 2.5rem;
  width: 100%;
  max-width: 1000px;
  margin: 20px;
  position: relative;
  animation: scaleIn 0.3s ease-out;
  box-shadow: 0 0 80px rgba(74, 58, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.close-button {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border: none;
  color: #fff;
  padding: 10px;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.close-button:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(90deg);
}

.shop-title {
  font-size: 2.5rem;
  font-weight: 800;
  background: linear-gradient(135deg, #4a3aff 0%, #8e24ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.5rem;
  text-align: left;
}

.shop-subtitle {
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.shop-categories {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.category-button {
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.category-button.active {
  background: rgba(255, 255, 255, 0.1);
  color: white;
}

.shop-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  max-height: 60vh;
  overflow-y: auto;
  padding-right: 1rem;
}

.shop-grid::-webkit-scrollbar {
  width: 8px;
}

.shop-grid::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.shop-grid::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.shop-card {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 20px;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.shop-card:hover {
  background: rgba(255, 255, 255, 0.05);
  transform: translateY(-2px);
}

.icon-container {
  width: 56px;
  height: 56px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card-header {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.card-info {
  flex: 1;
}

.card-title {
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
}

.card-subtitle {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
  margin-top: 0.2rem;
}

.card-description {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9rem;
  line-height: 1.4;
  margin: 0.5rem 0;
}

.card-stats {
  display: flex;
  gap: 1rem;
  margin-top: auto;
}

.stat {
  flex: 1;
  background: rgba(255, 255, 255, 0.03);
  padding: 0.5rem;
  border-radius: 8px;
  text-align: center;
}

.stat-value {
  color: white;
  font-weight: bold;
  font-size: 1.1rem;
}

.stat-label {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8rem;
}

.btn-purchase {
  background: linear-gradient(135deg, #4a3aff 0%, #8e24ff 100%);
  color: white;
  border: none;
  padding: 1rem;
  border-radius: 12px;
  font-weight: bold;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.btn-purchase:hover:not(:disabled) {
  background: linear-gradient(135deg, #5747ff 0%, #9933ff 100%);
  transform: translateY(-1px);
}

.btn-disabled {
  background: rgba(255, 255, 255, 0.05) !important;
  color: rgba(255, 255, 255, 0.3) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  cursor: not-allowed !important;
  font-weight: bold;
  padding: 1rem;
  border-radius: 12px;
}

.level-required {
  color: #ff4757;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
