/* Add these to your existing OreLegend.css */

.progress {
  border-radius: 4px;
  overflow: hidden;
}

.progress-bar {
  transition: width 0.6s ease;
}

.ore-stat {
  min-width: 120px;
  justify-content: center;
}

.empty-state {
  color: rgba(255, 255, 255, 0.3);
  padding: 3rem;
  text-align: center;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.05);
}

/* Optional: Add a subtle animation to the progress bars */
@keyframes glowPulse {
  0% { opacity: 0.8; }
  50% { opacity: 1; }
  100% { opacity: 0.8; }
}

.progress-bar {
  animation: glowPulse 2s infinite;
}

.oreTotal {
  color: white;
}

.totalValue {
  color: white;
}
